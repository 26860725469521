<template>
  <div class="d-flex justify-center align-center main-container">
    <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "LoadingScreen",
  data() {
    return {
      longToken: "",
      auth: false,
      emailFromResponse: ""
    };
  },
  methods: {
    ...mapActions("beezIn", {
      longTokenValidation: "longTokenValidation"
      // beezInCodeValidation: "beezInCodeValidation"
    }),

    ...mapActions("auth", {
      reauth: "reauth"
    }),

    async validateLongToken() {
      let response = "";
      try {
        response = await this.longTokenValidation({
          token: this.longToken
        });
      } catch (error) {
        console.log(error);
        this.$router.replace({ name: "log-in" });
        return;
      }
      this.emailFromResponse =
        response.email !== undefined ? response.email : "";

      if (response.userExists == false) {
        this.$router.replace({
          name: "sign-up",
          query: { email: this.emailFromResponse }
        });
      } else {
        this.$router.replace({ name: "log-in" });
      }
    }
  },
  computed: {
    ...mapFields("beezIn", {
      beezinCode: "beezinCode"
    })
  },
  mounted() {
    const exp = this.$route.path.slice(1);
    const t = exp.split("/");
    this.longToken = t[1];
    this.validateLongToken();
  }
};
</script>

<style scoped>
.main-container {
  height: 100vh;
}
</style>
